import { memo } from 'react';

import { useTranslation } from 'next-export-i18n';

import Currency from '@/components/common/currency';
import { TipModeEnum } from '@/types';
import { Box, Chip, Typography } from '@mui/material';

import styles from './styles.module.scss';

type Props = {
  index: number;
  value: number;
  mostCommonTip?: number;
  selectedTip: number | undefined;
  handleSelectTip: (arg: number) => void;
  mode?: TipModeEnum;
  remaining: string;
};

const TipItem = memo(function TipItem({
  index,
  value,
  mostCommonTip,
  selectedTip,
  handleSelectTip,
  mode,
  remaining,
}: Props) {
  const { t } = useTranslation();
  const getContent = () => {
    if (
      mode === TipModeEnum.Percentage ||
      mode === TipModeEnum.PercentageWithHint
    ) {
      return (
        <>
          <Typography variant='body_md'>
            {value === 0 ? t('not-now') : `${value.toFixed(2)}%`}
          </Typography>
          {mode === TipModeEnum.PercentageWithHint && (
            <>
              <Currency variant='currency' />
              <Typography display='flex' gap='3px' variant='currency'>
                <Typography variant='currency'>
                  {(Number(remaining) * (value / 100)).toFixed(2)}
                </Typography>
              </Typography>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {value != 0 && <Currency variant='currency' />}
          <Typography variant='title_md'>
            {value === 0 ? t('not-now') : value.toFixed(2)}
          </Typography>
          {mode === TipModeEnum.FixedWithHint && (
            <Typography variant='caption'>{`(${Math.ceil(
              (value * 100) / Number(remaining),
            )}%)`}</Typography>
          )}
        </>
      );
    }
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap='4px'>
      {mostCommonTip && mostCommonTip === value && (
        <Typography variant='caption' color='primary'>
          Most common
        </Typography>
      )}
      <Chip
        key={index}
        label={
          <Box display='flex' alignItems='baseline' gap='6px'>
            {getContent()}
          </Box>
        }
        variant={selectedTip === value ? 'filled' : 'outlined'}
        color={selectedTip === value ? 'primary' : 'secondary'}
        onClick={() => handleSelectTip(value)}
        className={styles.chip}
      />
    </Box>
  );
});

export default TipItem;
