import { memo } from 'react';

import Currency from '@/components/common/currency';
import { useCheckLinkUpdates, usePaymentLink } from '@/hooks';
import { Box, Container, Divider, Typography } from '@mui/material';

import styles from './styles.module.scss';

const LinkDetails = memo(function LinkDetails() {
  const { data } = usePaymentLink({ shouldUpdateStatus: true });
  useCheckLinkUpdates();

  return (
    <>
      <Container className={styles.linkDetailsContainer}>
        <Typography variant='body_sm'>{data?.meta?.reference}</Typography>
        <Box display='flex' flexDirection='row' gap='6px' alignItems='baseline'>
          <Currency variant='title_lg' />
          <Typography variant='title_lg'>
            {Number(data?.bill.billAmount || 0).toFixed(
              data?.currencyPrecision || 2,
            )}
          </Typography>
        </Box>
      </Container>
      {data?.meta?.description && (
        <>
          <Container className={styles.descriptionContainer}>
            <Typography
              variant='caption'
              color='var(--onSurfaceColors-highEmphasis)'
              whiteSpace='pre-wrap'
            >
              {data?.meta?.description}
            </Typography>
          </Container>
          <Divider />
        </>
      )}
    </>
  );
});

export default LinkDetails;
