import { ChangeEvent, memo, useEffect, useState } from 'react';

import { useTranslation } from 'next-export-i18n';

import { usePaymentLink, useRestaurant } from '@/hooks';
import { useStore } from '@/store';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import Currency from '../../common/currency';
import styles from './styles.module.scss';
import TipItem from './tip-item';

const Tips = memo(function Tips() {
  const { t } = useTranslation();
  const [isTipActive, setIsTipActive] = useState<boolean>(false);

  const { data } = useRestaurant();
  const { data: payment } = usePaymentLink();

  const selectedTip = useStore(state => state.selectedTip);
  const customTip = useStore(state => state.customTip);
  const setSelectedTip = useStore(state => state.setSelectedTip);
  const setCustomTip = useStore(state => state.setCustomTip);

  const handleSelectTip = (amount: number) => {
    if (selectedTip === amount) setSelectedTip(undefined);
    else {
      setCustomTip('0');
      setSelectedTip(amount);
    }
  };

  const handleCustomTip = () => {
    setIsTipActive(prev => !prev);
    setSelectedTip(undefined);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) setCustomTip('0');
    else setCustomTip(e.target.value.replace(/[^0-9]|^0+/g, ''));
  };

  useEffect(() => {
    if (data?.config.paymentLinkTipDefault) {
      setCustomTip('0');
      setSelectedTip(data.config.paymentLinkTipDefault);
    }
  }, [data?.config.paymentLinkTipDefault]);

  if (
    !data?.config.paymentLinkTipEnabled ||
    !data?.config.paymentLinkTipOptions
  )
    return null;

  return (
    <Container className={styles.tipContainer}>
      <Typography variant='title_md'>
        {t('do-you-want-to-include-a-tip')}
      </Typography>
      <Stack
        className={styles.tipItemContainer}
        direction='row'
        gap='8px'
        alignItems='flex-end'
      >
        {data.config.paymentLinkTipOptions.includes(0) && (
          <TipItem
            index={0}
            value={0}
            selectedTip={selectedTip}
            handleSelectTip={handleSelectTip}
            mode={data.config.paymentLinkTipMode}
            remaining={payment?.bill.remaining ?? '0'}
          />
        )}
        {data.config.paymentLinkTipDefault &&
          !data.config.paymentLinkTipOptions.includes(
            data.config.paymentLinkTipDefault,
          ) && (
            <TipItem
              index={-2}
              value={data.config.paymentLinkTipDefault}
              selectedTip={selectedTip}
              handleSelectTip={handleSelectTip}
              mode={data.config.paymentLinkTipMode}
              remaining={payment?.bill.remaining ?? '0'}
            />
          )}
        {data.config.paymentLinkTipOptions
          .filter(value => value > 0)
          .map((value, index) => (
            <TipItem
              key={index}
              index={index}
              value={value}
              selectedTip={selectedTip}
              handleSelectTip={handleSelectTip}
              mode={data.config.paymentLinkTipMode}
              remaining={payment?.bill.remaining ?? '0'}
            />
          ))}
      </Stack>
      {data.config.paymentLinkTipOptions.includes(-1) && (
        <Box
          className={
            customTip !== '0' && !isTipActive ? styles.customActiveChip : ''
          }
          display='flex'
          gap='8px'
          height='38px'
          alignItems='center'
          paddingX='16px'
        >
          <Edit onClick={handleCustomTip} />
          {isTipActive ? (
            <TextField
              variant='standard'
              type='tel'
              sx={{
                '& .MuiInputBase-root:before': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    disableTypography
                    sx={{ fontSize: '12px' }}
                    position='start'
                  >
                    <Currency variant='currency' />
                  </InputAdornment>
                ),
              }}
              value={customTip}
              onBlur={handleCustomTip}
              onChange={handleOnChange}
              autoFocus
            />
          ) : (
            <>
              {customTip !== '0' ? (
                <Box
                  display='flex'
                  gap='4px'
                  alignItems='baseline'
                  onClick={handleCustomTip}
                >
                  <Typography variant='body_sm'>{t('custom-tip')}</Typography>
                  <Currency variant='currency' />
                  <Typography variant='body_sm'>
                    {Number(customTip).toFixed(2)}
                  </Typography>
                </Box>
              ) : (
                <Typography variant='body_sm' onClick={handleCustomTip}>
                  {t('pay-custom-tip')}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </Container>
  );
});

export default Tips;
