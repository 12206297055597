import { memo, useCallback } from 'react';

import { useTranslation } from 'next-export-i18n';

import Currency from '@/components/common/currency';
import { useConfigContext, usePaymentLink, useRestaurant } from '@/hooks';
import { useStore } from '@/store';
import { TipModeEnum } from '@/types';
import { calculateTotalAmounts } from '@/utils';
import { getLocaleText } from '@clubpay/customer-common-module/src/component/MultiLocale';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { Collapse, Container, Stack, Typography } from '@mui/material';

import styles from './styles.module.scss';

const YouPay = memo(function YouPay() {
  const { t } = useTranslation();
  const { lang } = useQlubRouter();
  const { data } = usePaymentLink();
  const { data: restaurant } = useRestaurant();
  const { config } = useConfigContext();
  const selectedTip = useStore(state => state.selectedTip);
  const customTip = useStore(state => state.customTip);

  const getAmounts = useCallback(
    () =>
      calculateTotalAmounts({
        remaining: data?.bill.remaining,
        selectedTip,
        customTip,
        tipMode: restaurant?.config.paymentLinkTipMode ?? TipModeEnum.Default,
        currencyPrecision: data?.currencyPrecision,
        isTipEnabled: restaurant?.config.paymentLinkTipEnabled,
      }),
    [
      data?.bill.remaining,
      selectedTip,
      customTip,
      restaurant?.config.paymentLinkTipMode,
      data?.currencyPrecision,
      restaurant?.config.paymentLinkTipEnabled,
    ],
  );

  const getCommissionLabel = useCallback(
    () =>
      getLocaleText(
        config?.paymentLinkCustomerCommissionAlias ?? '',
        lang,
        'en',
      ),
    [lang],
  );

  return (
    <Container className={styles.yourPaymentContainer}>
      <Stack gap='8px'>
        {Number(data?.bill.remainingCommission) > 0 && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            gap={2}
          >
            <Typography
              variant='body_md'
              color='var(--onSurfaceColors-mediumEmphasis)'
            >
              {getCommissionLabel().trim() === ''
                ? t('customer-commission')
                : getCommissionLabel()}
            </Typography>
            <Stack direction='row' gap='4px' alignItems='baseline'>
              <Currency variant='currency' />
              <Typography
                variant='body_md'
                color='var(--onSurfaceColors-mediumEmphasis)'
              >
                {data?.bill.remainingCommission}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Collapse in={Number(getAmounts().tip) > 0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            gap={2}
          >
            <Typography
              variant='body_md'
              color='var(--onSurfaceColors-mediumEmphasis)'
            >
              {t('tip')}
            </Typography>
            <Stack direction='row' gap='4px' alignItems='baseline'>
              <Currency
                variant='currency'
                color='var(--onSurfaceColors-mediumEmphasis)'
              />
              <Typography
                variant='body_md'
                color='var(--onSurfaceColors-mediumEmphasis)'
              >
                {getAmounts().tip}
              </Typography>
            </Stack>
          </Stack>
        </Collapse>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='baseline'
          gap={2}
        >
          <Typography variant='title_md'>{t('you-pay')}</Typography>
          <Stack direction='row' gap='4px' alignItems='baseline'>
            <Currency variant='currency' />
            <Typography variant='headline_sm'>{getAmounts().total}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography variant='caption' color='var(--onSurfaceColors-disabled)'>
        {t('inclusive-of-all-taxes-and-charges')}
      </Typography>
    </Container>
  );
});

export default YouPay;
