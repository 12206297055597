import { useStore } from '@/src/store';
import { Typography } from '@mui/material';

import SaudiRiyalIcon from './symbols/SaudiRiyalIcon';

type Props = {
  variant: 'currency' | 'title_lg';
  color?: string;
};

const Currency = ({ variant, color }: Props) => {
  const initialLink = useStore(state => state.initialLink);

  const getIconSize = () => {
    switch (variant) {
      case 'currency':
        return 'medium';
      case 'title_lg':
        return 'large';
      default:
        return 'medium';
    }
  };

  if ((initialLink?.data.currency || '').toUpperCase() === 'SAR')
    return <SaudiRiyalIcon fontSize={getIconSize()} htmlColor={color} />;
  if (initialLink?.data.currency)
    return (
      <Typography variant={variant} display='inline' color={color}>
        {initialLink?.data.currency.toUpperCase()}
      </Typography>
    );
};

export default Currency;
